<template>
    <div class="page">
        <div class="form">
            <div class="item">
                <div class="lable">账号</div>
                <div class="content">
                    <el-select v-model="form.account" clearable placeholder="请选择账号">
                        <el-option v-for="el in accountList" :key="el" :label="el" :value="el"> </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">法人</div>
                <div class="content">
                    <el-select v-model="form.legal_person" clearable placeholder="请选择法人">
                        <el-option v-for="el in legal_personList" :key="el" :label="el" :value="el"> </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">第三方</div>
                <div class="content">
                    <el-input v-model="form.service"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">时间</div>
                <div class="content">
                    <el-date-picker
                        ref="picker"
                        v-model="form.time"
                        :unlink-panels="true"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="到期时间"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div class="item">
                <div class="lable">机型</div>
                <div class="content">
                    <el-input v-model="form.model"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">实例规格</div>
                <div class="content">
                    <el-input v-model="form.standards"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">系统</div>
                <div class="content">
                    <el-input v-model="form.system"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">vCPU</div>
                <div class="content">
                    <el-select v-model="form.cpu" clearable placeholder="请选择">
                        <el-option v-for="el in cpuList" :key="el" :label="el" :value="el"> </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">内存</div>
                <div class="content">
                    <el-select v-model="form.memory" clearable placeholder="请选择">
                        <el-option v-for="el in memoryList" :key="el" :label="el" :value="el"> </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="lable">内网宽带</div>
                <div class="content">
                    <el-input v-model="form.protected_brod"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">公网宽带</div>
                <div class="content">
                    <el-input v-model="form.public_brod"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">公网IP</div>
                <div class="content">
                    <el-input v-model="form.public_ip"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">内网IP</div>
                <div class="content">
                    <el-input v-model="form.protected_ip"></el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">地区</div>
                <div class="content">
                    <el-input v-model="form.district"></el-input>
                </div>
            </div>
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="取消" scale="4" @click.native="$emit('stop')"></VyIcon>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { VyIcon },
    props: {
        copyForm: Object,
    },
    data() {
        return {
            form: {
                account: "",
                legal_person: "",
                service: "",
                model: "",
                standards: "",
                system: "",
                cpu: "",
                memory: "",
                protected_brod: "",
                public_brod: "",
                public_ip: "",
                protected_ip: "",
                district: "",
                time: [new Date(), new Date()],
            },
            legal_personList: [],
            cpuList: [],
            memoryList: [],
            accountList: [],
        };
    },
    async created() {
        let { data } = await axios.get("/api/server/cpushow");
        this.cpuList = data.cpu.map(e => e.cpu);
        this.memoryList = data.memory.map(e => e.memory);
        let { data: data2 } = await axios.get("/api/server/legal_person");
        let arr = [];
        data2.forEach(el => {
            if (arr.findIndex(e => e === el.legal_person) !== -1) return;
            arr.push(el.legal_person);
        });
        this.legal_personList = arr;
        this.getAccount();
        //如果是复制而来
        if (this.copyForm.id) {
            let {
                legal_person,
                service,
                model,
                standards,
                system,
                cpu,
                memory,
                protected_brod,
                public_brod,
                public_ip,
                protected_ip,
                district,
            } = this.copyForm;
            this.form = {
                legal_person,
                service,
                model,
                standards,
                system,
                cpu,
                memory,
                protected_brod,
                public_brod,
                public_ip,
                protected_ip,
                district,
                time: [this.copyForm.startt_time, this.copyForm.endt_time],
                startt_time: this.copyForm.startt_time,
                endt_time: this.copyForm.endt_time,
            };
        }
    },
    methods: {
        // 获取所有账号
        async getAccount() {
            let { data } = await axios.get("/api/project_pace/account");
            this.accountList = data;
        },
        async yes() {
            // 表单校验
            if (!this.form.legal_person) return this.$message.error("必填项：法人");
            // -----------
            let time = {};
            if (typeof this.form.time[0] !== "string") {
                time = {
                    startt_time: `${this.form.time[0].getFullYear()}-${
                        this.form.time[0].getMonth() + 1
                    }-${this.form.time[0].getDate()}`,
                    endt_time: `${this.form.time[1].getFullYear()}-${
                        this.form.time[1].getMonth() + 1
                    }-${this.form.time[1].getDate()}`,
                };
            }

            let { data } = await axios.post(
                "/api/server/add",
                qs.stringify({
                    ...this.form,
                    ...time,
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.$emit("stop");
        },
    },
    beforeDestroy() {
        this.$refs.picker.$destroy();
    },
};
</script>

<style lang="less" scoped>
.page {
    .form {
        background-color: white;
        border-radius: 20px;
        padding: 40px;
        margin-top: 20px;
        .item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            .lable {
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: white;
                font-size: 16px;
                border: 0;
                border-radius: 20px 0 0 20px;
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }
            .content {
                flex: 1;
                display: flex;
                align-items: center;
                white-space: nowrap;

                /deep/.el-input__inner {
                    flex: 1;
                }
                /deep/.el-select {
                    flex: 1;
                }
                /deep/.el-switch {
                    // margin-left: 20px;
                    margin: 0 20px;
                }
                /deep/.el-switch__label.is-active {
                    color: #7781f1;
                }
                /deep/.el-tag {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
