<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>服务器</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ addPageShow ? "添加服务器" : "列表" }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_headerSecondary" v-show="!addPageShow">
                <div class="_add">
                    <el-select v-model="status" clearable placeholder="选择时间状态检索" @change="onChange">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="紧急" value="2"></el-option>
                        <el-option label="失效" value="3"></el-option>
                    </el-select>
                    <button @click="addPageShow = true" v-if="$userLevel == 1">添加服务器</button>
                </div>
                <div>
                    <vy-search-box
                        v-model="value"
                        :btn-click="search"
                        placeholder="请输入内容"
                        :select-list="[
                            { label: '第三方', value: 'service' },
                            { label: '法人', value: 'legal_person' },
                        ]"
                        :select-value="select"
                        :select-clearable="false"
                        @change="val => (select = val)"
                        select
                    ></vy-search-box>
                </div>
            </div>
            <!-- 列表 -->
            <div class="page_content" v-show="!addPageShow">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="id" align="center" label="id"> </el-table-column>
                    <el-table-column prop="account" align="center" label="账号"> </el-table-column>
                    <el-table-column prop="service" align="center" label="第三方"> </el-table-column>
                    <el-table-column prop="legal_person" align="center" label="法人"> </el-table-column>
                    <el-table-column prop="" align="center" label="上期时间">
                        <template slot-scope="{ row }">
                            <span>{{ row.start_time }}至{{ row.end_time }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" align="center" label="本期时间">
                        <template slot-scope="{ row }">
                            <span>{{ row.startt_time }}至{{ row.endt_time }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="much_time" align="center" label="剩余天数"> </el-table-column>
                    <el-table-column prop="status" align="center" label="操作状态">
                        <template slot-scope="{ row }">
                            <span :style="{ color: row.status == '1' ? 'rgb(182, 109, 255)' : 'rgb(254, 124, 150)' }">{{
                                row.status == "1" ? "正常" : "失效"
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="time_status" align="center" label="时间状态">
                        <template slot-scope="{ row }">
                            <span
                                :style="{
                                    color:
                                        row.time_status == '1'
                                            ? 'rgb(182, 109, 255)'
                                            : row.time_status == '2'
                                            ? 'orange'
                                            : 'rgb(254, 124, 150)',
                                }"
                                >{{ row.time_status == "1" ? "正常" : row.time_status == "2" ? "紧急" : "失效" }}</span
                            >
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="{ row }">
                            <div class="_add">
                                <button @click="set(row)" v-if="$userLevel == 1">增量</button>
                            </div>
                            <div class="_operate">
                                <button @click="copy(row)" v-if="$userLevel == 1">复制</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)" v-if="$userLevel == 1">删除</button>
                            </div>
                            <div class="_look">
                                <button
                                    @click="
                                        set(row);
                                        is_look = true;
                                    "
                                >
                                    查看
                                </button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页器 -->
                <vy-pager
                    :count="total"
                    :page-size="pageSize"
                    :page="currentPage"
                    @current-change="currentChange"
                    @size-change="sizeChange"
                ></vy-pager>
            </div>
            <!-- 添加服务器 -->
            <template v-if="addPageShow">
                <addServe @stop="stop" :copyForm="copyForm"></addServe>
            </template>
            <!-- 增量（编辑） -->
            <el-dialog
                :title="is_look ? '查看' : '增量'"
                :visible.sync="dialogStatus"
                width="60%"
                @closed="is_look = false"
                :before-close="bfc"
            >
                <div class="dialog">
                    <div class="form">
                        <div class="item">
                            <div class="lable">账号</div>
                            <div class="content">
                                <el-select v-model="form.account" clearable placeholder="请选择账号" :disabled="is_look">
                                    <el-option v-for="el in accountList" :key="el" :label="el" :value="el"> </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">法人</div>
                            <div class="content">
                                <el-select v-model="form.legal_person" clearable placeholder="请选择法人" :disabled="is_look">
                                    <el-option v-for="el in legal_personList" :key="el" :label="el" :value="el"> </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">第三方</div>
                            <div class="content">
                                <el-input v-model="form.service" :disabled="is_look"></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">时间</div>
                            <div class="content">
                                <el-date-picker
                                    v-model="form.time"
                                    :unlink-panels="true"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始时间"
                                    end-placeholder="到期时间"
                                    :disabled="is_look"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">机型</div>
                            <div class="content">
                                <el-input v-model="form.model" :disabled="is_look"></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">实例规格</div>
                            <div class="content">
                                <el-input v-model="form.standards" :disabled="is_look"></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">系统</div>
                            <div class="content">
                                <el-input v-model="form.system" :disabled="is_look"></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">vCPU</div>
                            <div class="content">
                                <el-select v-model="form.cpu" clearable placeholder="请选择" :disabled="is_look">
                                    <el-option v-for="el in cpuList" :key="el" :label="el" :value="el"> </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">内存</div>
                            <div class="content">
                                <el-select v-model="form.memory" clearable placeholder="请选择" :disabled="is_look">
                                    <el-option v-for="el in memoryList" :key="el" :label="el" :value="el"> </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">内网宽带</div>
                            <div class="content">
                                <el-input v-model="form.protected_brod" :disabled="is_look"></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">公网宽带</div>
                            <div class="content">
                                <el-input v-model="form.public_brod" :disabled="is_look"></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">公网IP</div>
                            <div class="content">
                                <el-input v-model="form.public_ip" :disabled="is_look"></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">内网IP</div>
                            <div class="content">
                                <el-input v-model="form.protected_ip" :disabled="is_look"></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <div class="lable">地区</div>
                            <div class="content">
                                <el-input v-model="form.district" :disabled="is_look"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="BTNS" v-if="!is_look">
                        <button @click="dialogStatus = false">取消</button>
                        <button @click="yes_set">确认</button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import addServe from "./addServe.vue";
import Cookies from "js-cookie";
import qs from "qs";
import VyPager from "@/components/customs/VyPager.vue";
import VySearchBox from "@/components/customs/VySearchBox.vue";

export default {
    components: { addServe, VyPager, VySearchBox },
    data() {
        return {
            tableData: [],
            addPageShow: false,
            dialogStatus: false,
            form: {},
            copyForm: {},
            currentPage: 1,
            // 总条数
            total: 0,
            pageSize: 10,
            // 时间状态检索的三种情况
            status: "",
            // 搜索指定的某字段key   默认第三方service
            select: "service",
            // 搜索指定的某字段key的value
            value: "",
            legal_personList: [],
            cpuList: [],
            memoryList: [],
            accountList: [],
            // 查看
            is_look: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
        this.getDialogData();
        this.getAccount();
    },
    methods: {
        async getData() {
            let { id, status, select, value, currentPage: page, pageSize } = this;
            let obj = { id, status, page, pageSize };
            obj[select] = value;
            let {
                data: { data, count },
            } = await axios.post("/api/server/show", obj);
            this.total = count;
            return data;
        },
        // 获取所有账号
        async getAccount() {
            let { data } = await axios.get("/api/project_pace/account");
            this.accountList = data;
        },
        async getDialogData() {
            let { data } = await axios.get("/api/server/cpushow");
            this.cpuList = data.cpu.map(e => e.cpu);
            this.memoryList = data.memory.map(e => e.memory);
            let { data: data2 } = await axios.get("/api/server/legal_person");
            let arr = [];
            data2.forEach(el => {
                if (arr.findIndex(e => e === el.legal_person) !== -1) return;
                arr.push(el.legal_person);
            });
            this.legal_personList = arr;
        },
        // 删除一项
        async del(row) {
            let s = await this.$confirm("是否确认删除服务器?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => true);
            if (!s) return;
            let { data } = await axios.delete("/api/server/del?id=" + row.id);
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
        // 打开编辑项
        set(row) {
            this.form = {
                ...row,
                time: [row.startt_time, row.endt_time],
            };
            this.dialogStatus = true;
        },
        async yes_set() {
            let time = {};
            if (typeof this.form.time[0] !== "string") {
                time = {
                    startt_time: `${this.form.time[0].getFullYear()}-${
                        this.form.time[0].getMonth() + 1
                    }-${this.form.time[0].getDate()}`,
                    endt_time: `${this.form.time[1].getFullYear()}-${
                        this.form.time[1].getMonth() + 1
                    }-${this.form.time[1].getDate()}`,
                };
            }

            let { data } = await axios.post(
                "/api/server/update",
                qs.stringify({
                    ...this.form,
                    ...time,
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
            this.dialogStatus = false;
        },
        // 时间状态选择后
        async onChange(val) {
            this.tableData = await this.getData();
        },
        // 点击搜索
        async search() {
            this.tableData = await this.getData();
        },
        // 当前页变化时
        async currentChange(currentPage) {
            this.currentPage = currentPage;
            this.tableData = await this.getData();
        },
        async sizeChange(currentPageSize) {
            this.pageSize = currentPageSize;
            this.tableData = await this.getData();
        },
        // 从添加返回列表页
        async stop() {
            this.copyForm = {};
            this.addPageShow = false;
            this.tableData = await this.getData();
        },
        // 复制
        copy(row) {
            let f = { ...row };
            f.legal_person = "";
            this.copyForm = f;
            this.addPageShow = true;
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        & > div {
            flex: 1;
        }
        .searchBtn {
            &:hover {
                border-color: transparent !important;
            }
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
.dialog {
    .form {
        border-radius: 20px;
        .item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            .lable {
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: white;
                font-size: 16px;
                border: 0;
                border-radius: 20px 0 0 20px;
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }
            .content {
                flex: 1;
                display: flex;
                align-items: center;
                white-space: nowrap;

                /deep/.el-input__inner {
                    flex: 1;
                }
                /deep/.el-select {
                    flex: 1;
                }
                /deep/.el-switch {
                    // margin-left: 20px;
                    margin: 0 20px;
                }
                /deep/.el-switch__label.is-active {
                    color: #7781f1;
                }
                /deep/.el-tag {
                    margin-left: 10px;
                }
            }
        }
    }
    .BTNS {
        text-align: right;
        button {
            width: 70px;
            height: 40px;
            border-radius: 10px;
            border: none;
            margin-left: 10px;
            cursor: pointer;
        }
        & > button:nth-of-type(2) {
            background: linear-gradient(90deg, #da8cff, #9a55ff) !important;
            color: white;
        }
    }
}
</style>
